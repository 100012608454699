const delimiter = '\x1E'

export function chunkToTokens(chunk: string) {
  const segments = chunk.slice(1).split(delimiter)

  if (segments.length) {
    return segments
      .flatMap(segment => JSON.tryParse(segment))
      .filter(is.token)
  }

  return []
}

export function tokensToChunk(tokens: Many<Dx.Ai.Token>) {
  return `${delimiter}${JSON.stringify(tokens)}`
}